<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('languages.view')">
          <actions
            slot="actions"
            crud-links="languages"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ language.name }}
          </text-list>
          <text-list :title="$t('tables.headings.alpha_code')">
            {{ language.alpha_code }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <va-card :title="$t('language.translation')">
          <div class="code-pre">
            <div slot="content">
              <pre>
                <code class="json">{{ formattedJson }}</code>
              </pre>
            </div>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'languages-view',
  components: {
    Actions,
    TextList,
  },
  data () {
    return {
      error: false,
      loading: false,
      language: {
        translation: '{}',
      },
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    formattedJson () {
      const json = JSON.parse(this.language.translation) || {}
      return JSON.stringify(json, null, 4)
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `languages/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const languageId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(languageId))
      } catch (err) {
        // console.log('Error fetching language data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.language = { ...u.data.data }
    },
  },
}
</script>
